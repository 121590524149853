import React from 'react';
import { useEnvironment, useBi } from '@wix/yoshi-flow-editor';

import { useSettings } from '@wix/tpa-settings/react';
import s from './ProductBuyForSelection.scss';
import { classes } from './ProductBuyForSelection.st.css';
import cx from 'classnames';
import settingsParams from '../../../settingsParams';
import { BoxSelection, BoxSelectionBoxSize } from 'wix-ui-tpa';
import { useState } from '../../stores';

const ProductBuyForSelection = () => {
  const {
    state: { checkout },
    dispatch,
  } = useState();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const bi = useBi();

  return (
    <>
      <fieldset className={s.textFieldContainer}>
        <legend className={s.label}>
          {settings.get(settingsParams.giftCardForLabel)}
        </legend>
        <BoxSelection
          data-hook="rise-gift-card-product-buy-for-box-selection"
          name="buyfor-selection"
          className={classes.boxSelection}
          size={BoxSelectionBoxSize.small}
          onChange={({ id }) => {
            bi.report({
              evid: 54,
              src: 125,
              endpoint: 'gc-client-sf',
              params: {
                button: id,
              },
            });
            return dispatch({
              type: 'setPurchaseOptions',
              payload: {
                isGift: id === 'someoneElse',
              },
            });
          }}
        >
          <BoxSelection.Option
            className={cx(
              s.BoxSelectionOption,
              isMobile && s.BoxSelectionOptionMobile,
            )}
            id="someoneElse"
            checked={checkout.purchaseOptions.isGift}
          >
            {settings.get(settingsParams.someoneElseButton)}
          </BoxSelection.Option>
          <BoxSelection.Option
            className={s.BoxSelectionOption}
            id="myself"
            checked={!checkout.purchaseOptions.isGift}
          >
            {settings.get(settingsParams.myselfButton)}
          </BoxSelection.Option>
        </BoxSelection>
      </fieldset>
    </>
  );
};

export default ProductBuyForSelection;
